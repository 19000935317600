import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";

const Auditorium= lazy(() => import("./pages/auditorium"))
const Lobby= lazy(() => import("./pages/lobby"))
const Login= lazy(() => import("./pages/login"))
const Register= lazy(() => import("./pages/register/register"))
const Map= lazy(() => import("./pages/map"))
const standVinilit= lazy(() => import("./pages/stands/vinilit/standVinilit"))
const standNicoll= lazy(() => import("./pages/stands/nicoll/standNicoll"))
const standDurman= lazy(() => import("./pages/stands/durman/standDurman"))
const PhotoBooth= lazy(() => import("./pages/photobooth/photobooth"))
const Admin= lazy(() => import("./pages/admin/admin"))
/* const Cover= lazy(() => import("./pages/cover")) */
const Questions= lazy(() => import("./pages/questions/questions"))
const P404= lazy(() => import("./pages/page404/page404"))

const Routes = () => {
  return (
      <BrowserRouter>
        <Suspense fallback={<div>Cargando...</div>}>
          <Switch>
            <Route exact path={["/", "/registro", "/login"]} component={Register} />
            <Route exact path="/lobby" component={Map} />
            <Route exact path="/stands" component={Lobby} />
            <Route exact path="/stands/stand" component={standVinilit} />
            <Route exact path="/stands/nicoll" component={standNicoll} />
            <Route exact path="/stands/durman" component={standDurman} />
            <Route exact path="/room1" component={Auditorium} />
            <Route exact path="/room2" component={Auditorium} />
            <Route exact path="/photobooth" component={PhotoBooth} />
            <Route exact path="/preguntas-admin" component={Questions} />
            {/* <Route exact path="/room" component={Auditorium} />
            <Route exact path="/lobby-stands" component={Lobby} />
            <Route exact path="/stand-epi-cx" component={StandEpiCx} />
            <Route exact path="/stand-the-lab" component={StandTheLab} />
            <Route exact path="/config-admin-questions" component={Questions} />
            <Route exact path="/config-admin-score" component={TopTen} /> */}
            <Route exact path="/config-admin" component={Admin} />
            <Route component={P404}/>
          </Switch>
        </Suspense>
      </BrowserRouter>
  );
}

export default Routes

