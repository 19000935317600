import React from "react";
import ReactDOM from "react-dom";
import "./index.css"
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Routes from "./routes";

ReactDOM.render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <Routes />
    </DndProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
